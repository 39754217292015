import { get } from 'lodash';

import { createSelector } from '@reduxjs/toolkit';
import { actionTypes, getCompleteFaqItems } from 'actions/campaign';
import { createLoadingSelector } from 'reducers/ui';
import { formatItems } from 'selectors/facet';

import { usersItemsSelectorFactory } from './entities';

/**
 * Get the tag set list in format for the dropdowns options props
 *
 * @param {*} state Redux state
 */
const generateTagSetsOptions = (tagSets) =>
  tagSets.map((tagSet) => ({
    key: tagSet.id,
    value: tagSet.id,
    text: tagSet.name,
  }));

/**
 * Get the default value, the display value and if the value is the one currently saved in db for a specific field in a specific state
 *
 * @param {*} state Redux state
 * @param {*} campaignId Id of the campaign being customized
 * @param {*} page Page of the campaign where the field is at
 * @param {*} inputKey Key of the field to get
 * @return {*} Default value, displayed value, is the value displayed default
 */
const getWordingValue = (campaignCustomization, campaignId, page, inputKey) => {
  const defaultWording =
    campaignCustomization.currentCampaignConfiguration.configuration_ui.wording[
      page
    ][inputKey];
  const customWording = get(
    campaignCustomization.diff.configuration_ui?.wording,
    [page, inputKey],
    defaultWording
  );
  return {
    defaultWording,
    value: customWording,
    isDefault: defaultWording === customWording,
  };
};

export const previewActivatedSelector = (state) =>
  state.campaign.previewActivated;
export const restorationActivatedSelector = (state) =>
  state.campaign.restorationActivated;
export const hasUnsavedChangesSelector = (state) =>
  state.campaign.hasUnsavedChanges;

export const campaignCustomizationSelectorFactory = (campaignId) => (state) =>
  state.campaign.customization[campaignId];
export const campaignSatisfactionTagSetIdSelectorFactory =
  (campaignId) => (state) =>
    state.campaign.customization[campaignId]?.currentCampaignConfiguration
      ?.satisfaction_tag_set?.id;

export const hasUnpublishedChangesSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) => customization?.hasUnpublishedChanges
  );

export const campaignConfigurationLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
  actionTypes.RESET_PREVIEW_CONFIG_REQUEST,
  actionTypes.SAVE_CAMPAIGN_CONFIGURATION_REQUEST,
]);

export const campaignFaqItemsDeletionSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (campaignCustomization) => campaignCustomization?.faq_items_deletion
  );

export const faqItemsCurrentSizeSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (campaignCustomization) => campaignCustomization?.faq_items_current_size
  );
export const isFaqItemsEmptySelectorFactory = (campaignId) =>
  createSelector(
    campaignFaqItemsDeletionSelectorFactory(campaignId),
    faqItemsCurrentSizeSelectorFactory(campaignId),
    (faqItemsDeletion, faqItemsCurrentSize) =>
      faqItemsDeletion &&
      faqItemsDeletion?.length === faqItemsCurrentSize?.faq_items_current_size
  );
export const faqItemsSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) => getCompleteFaqItems(customization)
  );

export const wordingValueSelectorFactory = (campaignId, page, inputKey) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) =>
      getWordingValue(customization, campaignId, page, inputKey)
  );

export const onGenerateTagSetsOptionsSelector = createSelector(
  (state) => state.campaign.tagSets,
  generateTagSetsOptions
);

export const campaignConfigurationDiffSelectorFactory =
  (campaignId) => (state) =>
    state.campaign.customization[campaignId]?.diff || null;

export const tagSetDropdownSelectorFactory = (campaignId, tagSetId) =>
  createSelector(
    (state) => state.campaign.tagSets,
    (tagSets) =>
      tagSets
        .find(({ id }) => id === tagSetId)
        ?.items.map(({ id, name, color }) => ({
          value: id,
          key: id,
          text: name,
          color,
        })) || null
  );

export const campaignsSelector = (state) => state.campaign.campaigns;
export const campaignItemsSelector = createSelector(
  campaignsSelector,
  (campaigns) =>
    campaigns?.map((campaign) => ({
      key: campaign.id,
      value: campaign.id,
      text: campaign.name,
    })) || []
);

export const campaignNameFromListSelectorFactory = (campaignId) =>
  createSelector(
    campaignsSelector,
    (campaigns) =>
      campaigns.find((campaign) => campaign.id === campaignId)?.name
  );

export const campaignSelector = (campaignId) => (state) =>
  state.campaign.customization?.[campaignId]?.currentCampaignConfiguration;

export const formSelectorFactory = (campaignId, field, withDiff = false) =>
  createSelector(
    campaignSelector(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) => {
      const form = [...(configuration?.[field] || [])];
      if (withDiff) {
        const formIds = form.map(({ id }) => id);
        diff?.[field]?.forEach((item) => {
          const formIdsIndex = formIds.findIndex((id) => id === item.id);
          if (formIdsIndex > -1) {
            form[formIdsIndex] = item;
          } else {
            form.push(item);
          }
        });
      }
      return form?.sort((a, b) => a.position - b.position);
    }
  );

export const randomizeSelectorFactory = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) =>
      diff.randomize !== undefined ? diff.randomize : configuration.randomize
  );

export const expandedFormSelectorFactory = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) =>
      diff.configuration_ui?.expanded_form !== undefined
        ? diff.configuration_ui?.expanded_form
        : configuration.configuration_ui?.expanded_form
  );

export const highlightConceptsSelectorFactory = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) =>
      (diff.highlight_concepts || configuration.highlight_concepts).map(
        ({ id }) => id
      )
  );

export const formElementSelectorFactory = (
  campaignId,
  field,
  formId,
  loadTagSet = false
) =>
  createSelector(
    campaignSelector(campaignId),
    (state) => state,
    (configuration, state) => {
      const formElement =
        (configuration &&
          configuration[field] &&
          configuration[field].find((item) => item.id === formId)) ||
        {};

      if (loadTagSet && formElement) {
        return {
          formElement,
          tagSet: tagSetDropdownSelectorFactory(
            campaignId,
            formElement.tag_set?.id
          )(state),
        };
      }
      return formElement;
    }
  );

export const formElementLabelFormatterSelectorFactory = (campaignId) =>
  createSelector(
    formSelectorFactory(campaignId, 'annotation_form'),
    formSelectorFactory(campaignId, 'identification_form'),
    formSelectorFactory(campaignId, 'url_form'),
    (annotationForm, identificationForm, urlForm) => (formId) => {
      for (const form of [annotationForm, identificationForm, urlForm]) {
        const formElement = form.find((item) => item.id === formId);
        if (formElement) {
          return formElement.label;
        }
      }
      return '-';
    }
  );

export const campaignConfigurationIdentificationFormDeletionSelectorFactory = (
  campaignId,
  field,
  formId,
  loadTagSet = false
) =>
  createSelector(
    formSelectorFactory(campaignId, field, formId, loadTagSet),
    campaignCustomizationSelectorFactory(campaignId),
    (identificationForm, configuration) =>
      identificationForm?.map((formElement) => ({
        ...formElement,
        archived: configuration.identification_form_deletion.includes(
          formElement.id
        ),
      }))
  );

export const campaignTagSetsSelectorFactory = (campaignId) =>
  createSelector(campaignSelector(campaignId), (configuration) =>
    configuration ? configuration.tag_sets : []
  );

export const satisfactionTagItemsSelectorFactory = (campaignId) => () =>
  createSelector(
    campaignSatisfactionTagSetIdSelectorFactory(campaignId),
    (state) => state.entities.tagSets,
    (tagSetId, tagSetItems) =>
      formatItems(tagSetItems[tagSetId] || { items: [] })
  );

export const campaignOntologySelectorFactory = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (state) => state.entities.ontologiesById,
    (campaign, ontologies) => {
      const selectedOntology = ontologies[campaign.ontology.id];
      return {
        ...selectedOntology,
        concepts: (selectedOntology?.concepts || [])
          .filter((item) => item.is_non_attributed === false)
          .map((filteredItem) => ({
            key: filteredItem.id,
            value: filteredItem.id,
            text: filteredItem.name,
          })),
      };
    }
  );

export const campaignOntologyItemsSelectorFactory = (campaignId) => () =>
  createSelector(
    campaignOntologySelectorFactory(campaignId),
    (ontology) => ontology?.concepts || []
  );

export const publicationDateSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaign) => campaign?.publication_date || null
  );

export const firstPublicationDateSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaign) => campaign?.first_publication_date || null
  );

export const campaignNameSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaign) => campaign?.name || null
  );

export const campaignConfigurationIsInitializedSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (campaignCustomization) => !!campaignCustomization
  );

export const campaignAclSelectorFactory = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaign) => campaign?._acl_ || {}
  );

export const formElementUserItemsSelector = (
  campaignId,
  formElementId = null,
  withEmptyItems = false
) =>
  createSelector(
    formElementSelectorFactory(campaignId, 'annotation_form', formElementId),
    usersItemsSelectorFactory(withEmptyItems),
    (formElement, users) => {
      const userIds = formElement.users?.map(({ id }) => id) || [];
      return users.filter(({ value }) => userIds.includes(value));
    }
  );
